import Vue from 'vue'
import Vuex from 'vuex'
import {LANG} from './language'
import AuthModule from './AuthModule'
import FirebaseModule from './FirebaseModule'

Vue.use(Vuex)

const state = {
  navigation: {
    show: true,
    showHome: true,
    showApp: false,
    dark: true, // set and get
    theme: '#388E3C' // green darken-2
  },
  device: {
    platform: 'NaN'
  },
  user: {
    dark: localStorage.getItem('darkTheme') === null ? true : JSON.parse(localStorage.getItem('darkTheme')), // set and get
    uid: '',
    docId: '',
    email: '',
    displayName: '',
    aboutMe: '',
    fullName: '',
    address: '',
    dob: '',
    sex: 0,
    gravatar: false,
    avatar: '',
    hasCoinbase: false,
    hasWeb3InjectedBrowser: false,
    accounts: [],
    walletProvider: localStorage.getItem('provider') === null ? '' : localStorage.getItem('provider'),
    mmConnected: false,
    mmInstalled: false,
    mmCount: 0,
    newCount: 10,
    networkChainID: '',
    binanceConnected: false,
    binanceInstalled: false,
    binanceCount: 0,
    walletConnected: false,
    isDAppReady: false,
    isLoggedIn: false,
    isVerified: false,
    idVerified: false,
    phoneVerified: false,
    ownsNFTs: false,
    nftName: '',
    accLevel: 0,
    accType: 0,
    accStatus: 0,
    memberSince: 0,
    language: 'en', // localStorage.getItem('language') === null ? 'en' : localStorage.getItem('language'),
    favorites: [],
    likes: localStorage.getItem('likes') === null ? [] : JSON.parse(localStorage.getItem('likes')),
    welcome: null,
    showWelcomeSession: false,
    showWelcome: localStorage.getItem('welcome') === null ? false : JSON.parse(localStorage.getItem('welcome'))
  },
  latestBNB: 0,
  latestETH: 0,
  latestMATIC: 0,
  latestKCS: 0,
  paymentAddress: '0x6EDd445C22b80972C08F17EE2c066B31d3F9E8Dc',
  loading: false,
  error: null,
  lang: LANG
}

const mutations = {
  ShowNav (state) {
    state.navigation.show = true
  },
  HideNav (state) {
    state.navigation.show = false
  },
  SetTheme (state, payload) {
    state.user.dark = payload.dark
    localStorage.setItem('darkTheme', payload.dark)
  },
  SetLikes (state, payload) {
    state.user.likes.push(payload)
    localStorage.setItem('likes', JSON.stringify(state.user.likes))
  },
  SetNFTChanges (state, payload) {
    state.user.ownsNFTs = payload.ownsNFTs
    state.user.nftName = payload.nftName
  },
  RemoveLike (state, payload) { // payload === index
    state.user.likes.splice(payload, 1)
    localStorage.setItem('likes', JSON.stringify(state.user.likes))
  },
  SetLatestPriceBNB (state, payload) {
    state.latestBNB = payload.price
  },
  SetLatestPriceETH (state, payload) {
    state.latestETH = payload.price
  },
  SetLatestPriceMATIC (state, payload) {
    state.latestMATIC = payload.price
  },
  SetLatestPriceKCS (state, payload) {
    state.latestKCS = payload.price
  },
  setAvatar (state, payload) {
    state.user.gravatar = false
    state.user.avatar = payload
  },
  setWelcomeSession (state, payload) {
    state.user.showWelcomeSession = payload
  },
  setWelcome (state, payload) {
    state.user.showWelcome = payload
    localStorage.setItem('welcome', payload)
  },
  setUserTierLevel (state, payload) {
    // console.log('setUserTierLevel in Store')
    // console.log(payload)
    state.user.accLevel = payload
  },
  SetUserDetails (state, payload) {
    // console.log('SetUserDetails in Store')
    // console.log(payload)
    state.user.accounts = payload.accounts
    state.user.walletProvider = payload.walletProvider
    localStorage.setItem('provider', payload.walletProvider)
    state.user.mmConnected = payload.mmConnected
    state.user.mmInstalled = payload.mmInstalled
    state.user.binanceConnected = payload.binanceConnected
    state.user.binanceInstalled = payload.binanceInstalled
    state.user.walletConnected = payload.walletConnected
    state.user.isDAppReady = payload.isDAppReady
    state.user.networkChainID = payload.networkChainID
    state.user.mmCount = payload.mmCount
  },
  SetConnectedUserDetails (state, payload) {
    // console.log('SetUserDetails in Store')
    // console.log(payload)
    state.user.uid = payload.uid
    state.user.docId = payload.docId
    state.user.language = payload.language
    state.user.displayName = payload.displayName
    state.user.aboutMe = payload.aboutMe
    state.user.fullName = payload.fullName
    state.user.dob = payload.dob
    state.user.sex = payload.sex
    state.user.address = payload.address
    state.user.email = payload.email
    state.user.gravatar = payload.gravatar
    state.user.avatar = payload.avatar
    state.user.isLoggedIn = payload.isLoggedIn
    state.user.isVerified = payload.isVerified
    state.user.idVerified = payload.idVerified
    state.user.phoneVerified = payload.phoneVerified
    // state.user.accLevel = payload.accLevel
    state.user.accType = payload.accType
    state.user.accStatus = payload.accStatus
    state.user.memberSince = payload.memberSince
    state.user.favorites = payload.favorites
    state.user.welcome = payload.welcome
  },
  SetConnectedUserFalse (state, payload) {
    state.user.welcome = payload.welcome
  },
  SetMetaMaskChanges (state, payload) {
    state.user.accounts = payload.accounts
    state.user.mmConnected = payload.mmConnected
    state.user.walletProvider = payload.walletProvider
    localStorage.setItem('provider', payload.walletProvider)
    state.user.isLoggedIn = payload.isLoggedIn
  },
  SetBinanceChanges (state, payload) {
    state.user.accounts = payload.accounts
    state.user.binanceConnected = payload.binanceConnected
    state.user.walletProvider = payload.walletProvider
    localStorage.setItem('provider', payload.walletProvider)
    state.user.isLoggedIn = payload.isLoggedIn
  },
  SetWalletConnectChanges (state, payload) {
    state.user.accounts = payload.accounts
    state.user.walletConnected = payload.mmConnected
    state.user.walletProvider = payload.walletProvider
    localStorage.setItem('provider', payload.walletProvider)
    state.user.isLoggedIn = payload.isLoggedIn
  },
  SetMetaMaskInstalled (state, payload) {
    state.user.mmInstalled = payload.mmInstalled
  },
  SetBinanceInstalled (state, payload) {
    state.user.binanceInstalled = payload.binanceInstalled
  },
  SetNetworkChainID (state, payload) {
    state.user.networkChainID = payload.networkChainID
  },
  SetCount (state, payload) {
    state.user.mmCount = payload.mmCount
  },
  SetNewCount (state, payload) {
    state.user.newCount = payload
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setError (state, payload) {
    state.error = payload
  },
  clearError (state) {
    state.error = null
  }
}

const actions = {
  clearError ({commit}) {
    commit('clearError')
  }
}

const getters = {
  getDevice () {
    return state.device
  },
  loading (state) {
    return state.loading
  },
  error (state) {
    return state.error
  }
}

// This is store!!!.
const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    auth: AuthModule,
    fb: FirebaseModule
    // web3: Web3Module,
  }
})

export default store
