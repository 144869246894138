'use strict'
import axios from 'axios'

// Local Dev
 const NFT_API = 'http://127.0.0.1:3000/api/'

// Production Server
// const NFT_API = 'http://209.58.172.141:3000/api/'
const TMO_API = 'https://tokenmonster.app/api/v1/'
//

let getters = null
class OpenSeaAPI {
  constructor () {
    getters = getters || this
    return getters
  }


  async searchGraphql(search, mainnet) {
    const requestBody = {
      search: search,
      mainnet: mainnet
    }
    const config = {
      headers: {
        'Content-Type': 'application/json'
        // 'X-Authentication': token
      }
    }
    return axios.post(NFT_API + 'opensea/search', requestBody, config)
      .then(response => {
        // console.log(response)
        return response
      })
      .catch(e => {
        console.log(e)
      })
  }

  // Get the account collections from OpenSea
  async getMyCollections(walletAddress, mainnet, format, limit, offset) {
    // collections?asset_owner=0xf251d1b5215dd88DDa288689b2ceDC5f0843d7f4&format=json&limit=30&offset=0
    const requestBody = {
      walletAddress: walletAddress,
      mainnet: mainnet,
      format: format,
      limit: limit,
      offset: offset
    }
    const config = {
      headers: {
        'Content-Type': 'application/json'
        // 'X-Authentication': token
      }
    }
    return axios.post(NFT_API + 'opensea/getcollections', requestBody, config)
      .then(response => {
        // console.log(response)
        return response
      })
      .catch(e => {
        console.log(e)
      })
  }

  // assets?format=json&limit=20&offset=0&order_direction=desc&owner=0xf251d1b5215dd88DDa288689b2ceDC5f0843d7f4&collection=nft-trial-xxx
  // Get the account collections from OpenSea
  async getMyCollection(walletAddress, mainnet, format, limit, offset, slug) {
    const requestBody = {
      walletAddress: walletAddress,
      mainnet: mainnet,
      format: format,
      limit: limit,
      offset: offset,
      slug: slug
    }
    const config = {
      headers: {
        'Content-Type': 'application/json'
        // 'X-Authentication': token
      }
    }
    return axios.post(NFT_API + 'opensea/getcollection', requestBody, config)
      .then(response => {
        // console.log(response)
        return response
      })
      .catch(e => {
        console.log(e)
      })
  }

  // Get the Latest BNB Price >> On App init in app.vue every 2-3 minutes
  async getLatestBNBPrice() {

    const config = {
      headers: {
        'Content-Type': 'application/json'
        // 'X-Authentication': token
      }
    }
    return await axios.get(TMO_API + 'tokenmonster/getbnbprice', config)
      .then(response => {
        // console.log(response)
        return response
      })
      .catch(e => {
        console.log(e)
      })
  }

  // Get the Latest BNB Price >> On App init in app.vue every 2-3 minutes
  async getLatestMATICPrice() {

    const config = {
      headers: {
        'Content-Type': 'application/json'
        // 'X-Authentication': token
      }
    }
    return await axios.get(TMO_API + 'tokenmonster/getmaticprice', config)
      .then(response => {
        // console.log(response)
        return response
      })
      .catch(e => {
        console.log(e)
      })
  }

  // Get the Latest KCS Price >> On App init in app.vue every 2-3 minutes
  async getLatestKCSPrice() {

    const config = {
      headers: {
        'Content-Type': 'application/json'
        // 'X-Authentication': token
      }
    }
    return await axios.get(TMO_API + 'tokenmonster/getkcsprice', config)
      .then(response => {
        // console.log(response)
        return response
      })
      .catch(e => {
        console.log(e)
      })
  }

  getTokenBalance (tokenAddress, walletAddress, network) {
    const requestBody = {
      tokenAddress: tokenAddress,
      walletAddress: walletAddress,
      network: network
    }
    const config = {
      headers: {
        'Content-Type': 'application/json'
        // 'X-Authentication': token
      }
    }
    return axios.post(TMO_API + 'tokenmonster/gettokenbalance', requestBody, config)
      .then(response => {
        // console.log(response)
        return response
      })
      .catch(e => {
        console.log(e)
      })
  }

  // Get the Latest ETH Price >> On App init in app.vue every 2-3 minutes
  async getLatestETHPrice() {

    const config = {
      headers: {
        'Content-Type': 'application/json'
        // 'X-Authentication': token
      }
    }
    return await axios.get(TMO_API + 'tokenmonster/getethprice', config)
      .then(response => {
        // console.log(response)
        return response
      })
      .catch(e => {
        console.log(e)
      })
  }

  // Get the Total Supply >
  async getTotalSupply(tokenAddress, network) {
    const requestBody = {
      tokenAddress: tokenAddress,
      network: network,
    }
    const config = {
      headers: {
        'Content-Type': 'application/json'
        // 'X-Authentication': token
      }
    }
    return await axios.post(TMO_API + 'tokenmonster/gettotalsupply', requestBody, config)
      .then(response => {
        // console.log(response)
        return response
      })
      .catch(e => {
        console.log(e)
      })
  }

  // Get the Total Supply
  async getTotalHolders(tokenAddress, network) {
    const requestBody = {
      tokenAddress: tokenAddress,
      network: network,
    }
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }
    }
    return await axios.post(TMO_API + 'tokenmonster/getholders', requestBody, config)
      .then(response => {
        // console.log(response)
        return response
      })
      .catch(e => {
        console.log(e)
      })
  }


  // https://bscscan.com/token/tokenholderchart/0x8faf2ee2c77126a1d7c7ffd84333a665db84671a
  //
  // Get the Total Burn
  async getTotalBurned(tokenAddress, network) {
    const requestBody = {
      tokenAddress: tokenAddress,
      network: network,
    }
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }
    }
    return await axios.post(TMO_API + 'tokenmonster/getburned', requestBody, config)
      .then(response => {
        // console.log(response)
        return response
      })
      .catch(e => {
        console.log(e)
      })
  }

  // Get the Token Price in BNB
  getTokenPriceQuote (tokenAddress, network, totalSupply) {
    // check total Supply and define sell Amount to check price
    var buyAmount = 1000000000
    if (totalSupply < 1000) {
      buyAmount = 1
    } else if (totalSupply < 10000) {
      buyAmount = 10
    } else if (totalSupply < 100000) {
      buyAmount = 100
    } else if (totalSupply < 1000000) {
      buyAmount = 1000
    } else if (totalSupply < 10000000) {
      buyAmount = 10000
    } else if (totalSupply < 100000000) {
      buyAmount = 100000
    } else if (totalSupply < 1000000000) {
      buyAmount = 1000000
    } else if (totalSupply < 10000000000) {
      buyAmount = 10000000
    } else if (totalSupply < 100000000000) {
      buyAmount = 100000000
    } else if (totalSupply < 1000000000000) {
      buyAmount = 1000000000
    } else if (totalSupply < 10000000000000) {
      buyAmount = 100000000
    } else if (totalSupply < 100000000000000) {
      buyAmount = 1000000000
    } else if (totalSupply < 1000000000000000) {
      buyAmount = 1000000000
    } else if (totalSupply < 10000000000000000) {
      buyAmount = 1000000000
    } else if (totalSupply < 100000000000000000) {
      buyAmount = 10000000000
    } else if (totalSupply < 1000000000000000000) {
      buyAmount = 100000000000
    } else if (totalSupply < 10000000000000000000) {
      buyAmount = 1000000000000
    } else if (totalSupply < 100000000000000000000) {
      buyAmount = 10000000000000
    } else if (totalSupply < 1000000000000000000000) {
      buyAmount = 100000000000000
    } else if (totalSupply < 10000000000000000000000) {
      buyAmount = 1000000000000000
    } else if (totalSupply < 100000000000000000000000) {
      buyAmount = 10000000000000000
    } else if (totalSupply < 1000000000000000000000000) {
      buyAmount = 100000000000000000
    }
    const requestBody = {
      tokenAddress: tokenAddress,
      network: network,
      buyAmount: buyAmount // Depending on Total Sypply
    }
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }
    }
    return axios.post(TMO_API + 'tokenmonster/getpricequote', requestBody, config)
      .then(response => {
        // console.log(response)
        return response
      })
      .catch(e => {
        console.log(e)
      })
  }

  // ##################################################################################
  // ################################## KUCOIN (KCC) ##################################
  // ##################################################################################
  //
  // Get KuCoin (KCC) token Info
  async getTokenInfoKCC(tokenAddress, network) {
    const requestBody = {
      tokenAddress: tokenAddress,
      network: network,
    }
    const config = {
      headers: {
        'Content-Type': 'application/json'
        // 'X-Authentication': token
      }
    }
    return await axios.post(TMO_API + 'tokenmonster/getkccinfo', requestBody, config)
      .then(response => {
        // console.log(response)
        return response
      })
      .catch(e => {
        console.log(e)
      })
  }

  async getKCCPriceMc (tokenAddress, network) {
    const requestBody = {
      tokenAddress: tokenAddress,
      network: network,
    }
    const config = {
      headers: {
        'Content-Type': 'application/json'
        // 'X-Authentication': token
      }
    }
    return await axios.post(TMO_API + 'tokenmonster/getkccpricemc', requestBody, config)
      .then(response => {
        // console.log(response)
        return response
      })
      .catch(e => {
        console.log(e)
      })
  }

}
export default new OpenSeaAPI()
