import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/web/Home.vue'
// import Tokens from '../views/web/Tokens.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/mmobile',
    name: 'MMobile',
    component: Home
  },
  {
    path: '/event',
    name: 'Event',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/web/Event.vue')
  },
  /* {
    path: '/signupnext',
    name: 'signupnext',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/auth/SignupNext.vue')
  },
  {
    path: '/signin/:type',
    name: 'signin',
    meta: {
      requiresAuth: false
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/auth/Signin.vue')
  }, */
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
