'use strict'
import axios from 'axios'

// Local Dev
// const MORALIS_API = 'http://127.0.0.1:3000/api/'

// Production Server
// const MORALIS_API = 'http://209.58.172.141:3000/api/'
//
const MORALIS_API = 'https://deep-index.moralis.io/api/v2/'

let getters = null
class MolaNftAPI {
  constructor () {
    getters = getters || this
    return getters
  }


  // Get the Latest BNB Price >> On App init in app.vue every 2-3 minutes
  async getNftForWallet(walletAddress) {

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key' : 'HQ45rH10GU8B65Y3f1HYhg1iiQgbFoB2IBJjZD9sgyyElCaOFLdxxCCzALW3g1WB'
      }
    }
    return await axios.get(MORALIS_API + walletAddress + '/nft?chain=eth&format=decimal', config)
      .then(response => {
        // console.log(response)
        return response
      })
      .catch(e => {
        console.log(e)
      })
  }

}
export default new MolaNftAPI()
