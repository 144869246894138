<template>
  <v-app id="mola-nft-event-app">


    <v-app-bar
      :color="!scrolledHome && $route.name === 'Home' ||  !scrolledHome && $route.name === 'Event' ? 'transparent' : dark ? 'grey darken-4' : 'grey lighten-3'"
      v-if="show"
      flat
      fixed
      app
      :dark="dark"
      :clipped-left="clipped"
    >
      <!-- <v-btn v-show="show && drawer"  @click.stop="drawer = !drawer" icon style="margin-right:10px">
        <v-icon x-large color="deep-purple" >{{ !drawer ? 'mdi-menu' : 'mdi-menu-open' }}</v-icon>
      </v-btn> -->

      <v-img style="cursor: pointer;margin-top:45px"  v-scroll-to="'#home'" v-show="window.width > 770 && $route.name !== 'Event'" :src="dark ? logoSrcDark : logoSrc" max-width="100" max-height="100"></v-img>
      <v-img style="cursor: pointer;margin-top:45px"  v-scroll-to="'#home'" v-show="window.width < 770 && $route.name !== 'Event'" :src="dark ? logoSrcDark : logoSrc" max-width="100" max-height="100"></v-img>

      <v-spacer></v-spacer>
      <MetaMaskConnect v-show="false" style="width:190px" ref="mmConnect" buttonType="small" :windowWidth="window.width" :windowHeight="window.height" :dark="dark">
      </MetaMaskConnect>
      <BinanceConnect v-show="false" style="width:190px" ref="binanceConnect" buttonType="small" :windowWidth="window.width" :windowHeight="window.height" :dark="dark">
      </BinanceConnect>
      <WalletConnect v-show="false" style="width:190px" ref="walletConnect" buttonType="small" :windowWidth="window.width" :windowHeight="window.height" :dark="dark">
      </WalletConnect>
      <v-toolbar-items v-show="showHome && !drawer && $route.name !== 'home'" class="hidden-sm-and-down" v-if="window.width > 770">
        <!-- <v-btn x-large text @click="routerGo('/')" v-scroll-to="'#home'" color="deep-purple">Home</v-btn>
        <v-spacer></v-spacer>
        <v-btn x-large text @click="routerGo('/marketplace')" color="deep-purple">MARKETPLACE</v-btn>
        <v-spacer></v-spacer>
        <v-btn x-large text @click="routerGo('/mint')" color="deep-purple">MINT</v-btn>
        <v-spacer></v-spacer> -->
        <!-- <v-btn x-large v-if="!mmConnected && !binanceConnected && !walletConnected" text @click="connectWalletDialog = true" >Connect</v-btn> -->
        <v-spacer></v-spacer>
        <!-- <v-chip
          class="mt-4"
          color="pink"
          x-small
        >
          <span style="color:#FFF">{{ lang[getLanguage].COMING_SOON }}</span>
        </v-chip> -->
      </v-toolbar-items>
      <!-- <v-btn rounded v-if="!isMobileDevice" @click="routerGo('/')" :color="dark ? '#388E3C' : '#66BB6A'" v-scroll-to="'#buytmo'" >BUY TMO</v-btn> -->
      <!-- <v-btn rounded v-if="isMobileDevice && !scrolledHome && $route.name === 'Home'" :small="isMobileDevice" @click="routerGo('/')" :color="dark ? '#388E3C' : '#66BB6A'" v-scroll-to="'#buytmo'" >BUY TMO</v-btn> -->

        <v-menu
          bottom
          left
          offsetY
          :dark="dark"
          v-if="showApp && isLoggedIn && !drawer && $route.name !== 'Event'"
          width="300"
          max-width="300"
        >
          <template v-slot:activator="{ on }">
            <v-btn style="margin-left:10px" rounded depressed v-if="!isMobileDevice && !drawer" v-on="on" :color="dark ? 'grey darken-2' : 'E3F2FD'"  >
              <v-list-item-avatar :size="24" style="border-radius:5px;opacity:0.9" class="text-center">
                <v-gravatar v-if="gravatar" :email="getUser.displayName" />
                <v-img v-else-if="!gravatar && getUser.avatar !== ''" :src="getUser.avatar"></v-img>
                <v-gravatar v-else :email="getUser.displayName" />
              </v-list-item-avatar>
              Connected
            </v-btn>
            <v-list-item-avatar v-on="on" v-else :size="24" style="margin-right:10px;border-radius:5px;opacity:0.9;" class="text-center">
              <v-gravatar v-if="gravatar" :email="getUser.displayName" />
              <v-img v-else-if="!gravatar && getUser.avatar !== ''" :src="getUser.avatar"></v-img>
              <v-gravatar v-else :email="getUser.displayName" />
            </v-list-item-avatar>
          </template>

          <v-list :style="dark ? 'background:#212121' : 'background:#E3F2FD'">
            <v-list-item >
              <v-layout column align-center>
                <v-list-item-avatar :size="90" style="border-radius:10px;opacity:0.9;margin-right:0px;" class="text-center">
                  <v-gravatar v-if="gravatar" :email="getUser.displayName" />
                  <v-img v-else-if="!gravatar && getUser.avatar !== ''" :src="getUser.avatar"></v-img>
                  <v-gravatar v-else :email="getUser.displayName" />
                </v-list-item-avatar>
                <!-- <v-btn
                  absolute
                  dark
                  fab
                  bottom
                  color="grey"
                  x-small
                  style="margin-left:35px;margin-bottom:10px"
                >
                  <v-icon>mdi-camera</v-icon>
                </v-btn> -->
              </v-layout>
            </v-list-item>

            <v-list-item class="text-center" style="margin-top:-5px">
            <v-list-item-content>
                <v-list-item-title class="title text-truncate">
                  {{ getUser.displayName }}
                </v-list-item-title>
                <!-- <v-list-item-subtitle class="text-truncate">
                  {{ getUser.email }}
                </v-list-item-subtitle> -->
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="text-center" style="margin-top:-15px">
              <v-list-item-content >
                <span style="margin-left:10px;width:200px" class="wrap-text font-weight-medium">{{ getUser.accounts[0].substr(0, 10) + '.....' + getUser.accounts[0].substr(32, 42) }}</span>
              </v-list-item-content>
            </v-list-item>


            <!-- <v-layout column align-center style="margin-top:-7px">
              <v-layout>
                  <v-img :src="'/img/tiers/' + getUser.accLevel  + '.png'" max-width="32" max-height="32" style="opacity:0.8"></v-img>
                <v-list-item-content class="font-weight-bold" style="margin-top:-3px;margin-left:5px" >
                  <v-list-item-title v-if="getUser.accLevel === 1">FREE USER</v-list-item-title>
                  <v-list-item-title v-if="getUser.accLevel === 2">GOBLIN</v-list-item-title>
                  <v-list-item-title v-if="getUser.accLevel === 3">OGRE</v-list-item-title>
                </v-list-item-content>
              </v-layout>
            </v-layout> -->

            <v-divider></v-divider>
            <!-- <v-list-item  class="text-center">
              <v-list-item-content>
                <v-list-item-title >
                  {{ getUser.accType === 1 ? 'Artist' : getUser.accType === 2 ? 'Listener' : 'Investor' }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Level {{ getUser.accLevel}}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item> -->
            <v-list-item  class="text-center">
              <v-list-item-content>
              <!-- <v-btn :light="!dark" outlined to="/profile">Manage Your Profile</v-btn>
              <p class="mt-0"></p> -->

              <v-btn v-if="mmConnected" :light="!dark" outlined >
                <img src="/img/icons/metamask.png" style="max-width:28px;max-height:28px;padding-right:10px;"/>Connected
              </v-btn>
              <v-btn v-else-if="binanceConnected" :light="!dark" outlined >
                <img src="/img/icons/binance.png" style="max-width:28px;max-height:28px;padding-right:10px;"/>Connected
              </v-btn>
              <v-btn v-else-if="walletConnected" :light="!dark" outlined >
                <img src="/img/icons/walletconnect.png" style="max-width:28px;max-height:28px;padding-right:10px;"/>Connected
              </v-btn>

              <v-btn v-if="mmConnected || walletConnected" :light="!dark" outlined @click="disconnecWallet">
                <v-icon left color="red">mdi-close-circle-outline</v-icon>Disconnect
              </v-btn>

              <!-- <v-btn :light="!dark" outlined @click="signOut"><v-icon left>mdi-logout-variant</v-icon>Sign Out</v-btn> -->
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

      <!-- <v-btn @click.stop="openSearch" v-if="!isMobileDevice && !drawer" icon color="deep-purple"><v-icon >mdi-magnify</v-icon></v-btn> -->
      <v-btn v-if="!isMobileDevice && $route.name !== 'Event'" @click.stop="changeTheme" icon color="#9E9E9E"><v-icon >mdi-invert-colors</v-icon></v-btn>
    </v-app-bar>


    <!-- ################## Update Welcome Text Dialog ---->

  <v-dialog v-model="connectWalletDialog"  max-width="450">
    <v-card
     :dark="dark"
    >
    <v-toolbar flat>
      <v-card-title style="font-size:18px" class="font-weight-bold">Connect to a wallet</v-card-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="connectWalletDialog = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-layout column align-center pa-2>

      <MetaMaskConnect :isMobileDevice="isMobileDevice" style="width:320px" ref="mmConnect" buttonType="large" :windowWidth="window.width" :windowHeight="window.height" :dark="dark">
      </MetaMaskConnect>

      <p class="mt-0"></p>
      <BinanceConnect :isMobileDevice="isMobileDevice" style="width:320px" ref="binanceConnect" buttonType="large" :windowWidth="window.width" :windowHeight="window.height" :dark="dark">
      </BinanceConnect>

      <p class="mt-0"></p>
      <WalletConnect style="width:320px" ref="walletConnect" buttonType="large" :windowWidth="window.width" :windowHeight="window.height" :dark="dark">
      </WalletConnect>

    </v-layout>


      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="deep-purple" text @click="learnMorelDialog = true">Learn more</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

  <v-dialog v-model="searchDialog" fullscreen hide-overlay persistent transition="dialog-bottom-transition">
      <v-card :dark="dark">
        <v-toolbar>
          <v-btn icon @click="searchDialog = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-icon large style="padding-right:10px" >mdi-magnify</v-icon>
          <v-toolbar-title class="title text-truncate" >Search Token Monster</v-toolbar-title>
          <v-spacer></v-spacer>
          <!--<v-btn outlined color="green accent-4" small style="margin-right:5px" v-if="viewMusic < 2">
            <v-icon left>mdi-music-note-plus</v-icon>Advanced Search
          </v-btn> -->
        </v-toolbar>

          <v-row style="margin-left:8px;margin-right:8px;margin-top:20px"
            justify="center"
          >
            <v-col :cols="window.width < 770 ? 12 : 6">
              <v-text-field
                ref="search"
                v-model="search"
                outlined
                :dark="dark"
                placeholder="Search by Address / Token Name / Symbol..."
                maxlength="100"
                single-line
                append-icon="mdi-magnify"
                v-on:keyup="searchClicked"
                @click:append="searchClicked('click')"
              ></v-text-field>

              <v-layout column align-center v-show="loadingData">
                <p class="mt-2"></p>
                <v-progress-circular
                  indeterminate
                ></v-progress-circular>
              </v-layout>

              <v-layout pa-2 column align-center v-if="!loadingData && search !== '' && searchTrigger && searchResults.length > 0">
               <v-layout>
                 <span style="color:#388E3C;font-weight:bold;padding-right:15px">{{ searchResults.length }} Search results for '{{ search }}'</span>
                 <v-icon color="red lighten-3" @click="clearSearch">mdi-close-circle-outline</v-icon>
               </v-layout>

                 <v-list shaped :dark="dark" color="transparent" style="width:100%">
                   <template v-for="(item, index) in searchResults">
                    <v-list-item
                      link
                      :key="index + 'y'"
                      @click="openTokenDetail(item)"
                    >
                    <v-list-item-avatar style="border-radius: 2px">
                       <v-img v-if="item.logo !== ''" :src="item.logo"></v-img>
                       <v-img v-if="item.logo === ''" :src="tempImage"></v-img>
                     </v-list-item-avatar>

                      <v-list-item-content>
                        <span :style="window.width < 770 ? 'font-size:14px' : 'font-size:18px'" class="wrap-text font-weight-bold"> {{ item.name }} ({{ item.symbol }}) </span>

                        <v-list-item-subtitle v-if="item.launch_date < nowList " class="wrap-text font-weight-bold" style="margin-top:5px">MC ${{ numberWithCommas(item.mc) }} - Launched: {{ timeDifference(Math.round(item.launch_date / 1000)) }} - {{ numberWithCommas(item.total_holders) }} Holders
                        </v-list-item-subtitle>

                        <v-list-item-subtitle v-else class="wrap-text font-weight-bold" style="margin-top:5px">
                          <vue-countdown :time="item.launch_date - nowList" v-slot="{ days, hours, minutes }">
                            Launching in: {{ days }} Days {{ hours }} Hrs. {{ minutes }} Min.
                          </vue-countdown>
                        </v-list-item-subtitle>

                      </v-list-item-content>

                      <v-list-item-action>
                        <v-layout>

                          <v-chip style="margin-right:30px" v-if="item.launch_date > nowList && item.launch_date - nowList <  (1000 * 60 * 31) && window.width > 770"
                            color="green lighten-2"
                            :x-small="window.width < 770"
                          >
                            <v-icon left :x-small="window.width < 770">
                              mdi-rocket-launch-outline
                            </v-icon>
                            Launching Soon!
                          </v-chip>

                          <v-spacer></v-spacer>

                          <v-layout column align-center :style="window.width < 770 ? 'margin-right:10px;margin-top:5px' : 'margin-right:30px;margin-top:5px'">
                            <v-icon v-if="item.network === 'ETH'" color="blue-grey lighten-1">
                              mdi-ethereum
                            </v-icon>
                            <v-img v-if="item.network === 'BSC'" src="/img/icons/binance-coin-logo.png" style="max-width:22px;max-height:22px;padding-right:10px">
                            </v-img>
                          </v-layout>

                        <v-spacer></v-spacer>

                         <v-layout column align-center>
                           <v-btn icon small color="#434908" @click.stop="likeThisItem(item)">
                             <v-icon>
                               {{ lookupLiked(item) ? 'mdi-thumb-up' : 'mdi-thumb-up-outline' }}
                             </v-icon>
                           </v-btn>
                           <span style="font-size:10px"> {{ item.likes }} </span>
                         </v-layout>

                        </v-layout>
                      </v-list-item-action>

                    </v-list-item>
                    <v-divider
                      v-if="parseInt(index + 1) < searchResults.length"
                      :key="index + 'canvas'"
                    ></v-divider>
                  </template>
                </v-list>

            </v-layout>

            </v-col>
          </v-row>

      </v-card>
    </v-dialog>

  <!-- ############################### ATTACHMENT PREVIEW ##################### -->

     <v-dialog v-model="dialogFilePreview" :width="window.width < 800 ? '300' : '800'" height="auto" >
        <v-card>
         <v-layout >
          <v-spacer></v-spacer>
          <v-btn color="grey" @click="dialogFilePreview = false" icon small>
            <v-icon >mdi-close</v-icon>
          </v-btn>
        </v-layout >
         <v-layout column align-center>
           <iframe :src="fileName"
                   :width="window.width < 800 ? '300' : '800'" height="700">
           </iframe>
          <p class="mt-3"></p>
        </v-layout >
        </v-card>
     </v-dialog>

    <v-main>
      <router-view :dark="dark" :windowWidth="window.width" :windowHeight="window.height" v-on:searchCleared="clearSearch"
                   :search="search" :trigger="triggerSearch" :drawer="drawer" :scrollY="scrollY" :isMobileDevice="isMobileDevice"
                   v-on:termsClicked="openTerms" v-on:privacyClicked="openPrivacy" v-on:connectWalletClicked="connectWalletDialog = true"/>
    </v-main>

    <v-footer
      :style="dark ? 'opacity: 0.9' : 'opacity: 0.9'"
      app
      :dark="dark"
      :color="dark ? '#132f5d' : '#132f5d'"
    >

      <v-layout column align-center v-if="window.width < 768" >
        <v-btn text style="text-transform: none !important;" @click="gotoLink('https://www.molachillclub.com/terms-and-conditions')">Terms & Conditions</v-btn>
        <!-- <v-btn text style="text-transform: none !important;" @click="openPrivacy">Privacy Policy</v-btn> -->
      <p style="font-size:14px;padding-bottom:10px" class="text-center" >&copy; Copyright {{ new Date().getFullYear() }}.Mola Chill Club</p>
      </v-layout>

      <v-layout v-else >
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
          <v-btn text style="text-transform: none !important;" @click="gotoLink('https://www.molachillclub.com/terms-and-conditions')">Terms & Conditions</v-btn>
          <!-- <v-btn text style="text-transform: none !important;" @click="openPrivacy">Privacy Policy</v-btn> -->
        <v-spacer></v-spacer>
        <p style="font-size:14px;padding-top:10px" class="text-center" >&copy; Copyright {{ new Date().getFullYear() }}.Mola Chill Club</p>
      </v-layout>
    </v-footer>

  </v-app>
</template>

<script>
import { isMobile } from 'mobile-device-detect'
import store from '@/store/index'
import firebase from 'firebase/app'
import { db } from '@/main'
import MetaMaskConnect from '@/views/app/MetaMaskConnect'
import BinanceConnect from '@/views/app/BinanceConnect'
import WalletConnect from '@/views/app/WalletConnect'
import OpenSeaAPI from '@/clients/OpenSeaAPI'
export default {
  data: () => ({
    lang: '',
    isMobileDevice: false,
    drawer: false,
    loadingData: false,
    clipped: false,
    scrolled: false,
    scrolledHome: false,
    activeMenuAcc: false,
    connectWalletDialog: false,
    disconnectWalletDialog: false,
    learnMorelDialog: false,
    dialogFilePreview: false,
    searchDialog: false,
    fileName: '',
    resultPriceInterval: null,
    dark: false,
    trigger: false,
    nowList: new Date().getTime(),
    search: '',
    searchResults: [],
    searchTrigger: false,
    triggerSearch: false,
    showSearch: false,
    logoSrcHeadDark: '',
    logoSrc: '',
    logoSrcDark: '',
    srcPancake: '',
    window: {
      width: 0,
      height: 0
    },
    scrollY: 0,
  }),
  components: {
    MetaMaskConnect,
    BinanceConnect,
    WalletConnect
  },
  computed: {
    getLanguage () {
      return this.$store.state.user.language
    },
    show () {
      return this.$store.state.navigation.show
    },
    isLoggedIn () {
      return this.$store.state.user.isLoggedIn
    },
    mmConnected () {
      return this.$store.state.user.mmConnected
    },
    binanceConnected () {
      return this.$store.state.user.binanceConnected
    },
    walletConnected () {
      return this.$store.state.user.walletConnected
    },
    showHome () {
      return this.$store.state.navigation.showHome
    },
    showApp () {
      return true
    },
    getDark () {
      return this.$store.state.user.dark
    },
    getTheme () {
      return this.$store.state.navigation.theme
    },
    getUser () {
      return this.$store.state.user
    },
    gravatar () {
      return this.$store.state.user.gravatar
    },
    getLikes () {
      return this.$store.state.user.likes
    },
  },
  watch: {
    mmConnected () {
      if (this.mmConnected) {
        setTimeout(() => {
          this.connectWalletDialog = false
        }, 2000)
      }
    },
    binanceConnected () {
      if (this.binanceConnected) {
        setTimeout(() => {
          this.connectWalletDialog = false
        }, 2000)
      }
    },
    walletConnected () {
      if (this.walletConnected) {
        setTimeout(() => {
          this.connectWalletDialog = false
        }, 2000)
      }
    }
  },
  mounted () {
    this.dark = this.getDark
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('scroll', this.handleScroll)
    clearInterval(this.resultPriceInterval)
  },
  created () {
    // this.lang = this.$store.state.lang
    // console.log('isMobileDevice')
    // console.log(this.isMobileDevice)
    this.isMobileDevice = isMobile
    document.addEventListener("deviceready", this.onDeviceReady, false)
    window.addEventListener('resize', this.handleResize)
    window.addEventListener('scroll', this.handleScroll)
    this.handleResize()
    this.logoSrc = './img/logo/mola_music_logo.png'
    this.logoSrcDark = './img/logo/mola_music_logo_dark.png'
    // this.logoSrcList = './img/logo/tokenmonster_logo_list.png'
    this.logoSrcList = './img/logo/mola_music_logo.png'
    this.logoSrcListDark = './img/logo/mola_music_logo.png'
    this.srcPancake = './img/icons/pancakeswap.png'

    this.getLatestBNBETH()
    this.resultPriceInterval = setInterval(() => {
      this.getLatestBNBETH()
    }, 240000) // Every 4 minutes 60,000 * 4
    setTimeout(() => {
      this.initUser()
    }, 2000)
  },
  methods: {
    disconnecWallet () {
      if (this.mmConnected) {
        this.$refs.mmConnect.disconnectMetamask()
      } else if (this.binanceConnected) {
        this.$refs.mmConnect.disconnectBinance()
      } else  if (this.walletConnected) {
        this.$refs.mmConnect.disconnecWallet()
      }
    },
    initUser () {
      // console.log('firebase.auth().currentUser')
      // console.log(firebase.auth().currentUser)
      this.currentUser = firebase.auth().currentUser
      // Update Store isLoggedIn true
    },
    changeTheme () {
      this.dark = !this.dark
      store.commit('SetTheme', {
        dark: this.dark
      })
    },
    changeThemeMenu (dark) {
      this.dark = dark
      store.commit('SetTheme', {
        dark: this.dark
      })
    },
    getLatestBNBETH () {
      Promise.resolve(OpenSeaAPI.getLatestBNBPrice())
      .then(result => {
        // console.log('result getLatestBNBPrice')
        // console.log(result)
        // console.log(parseFloat(result.data.result.ethusd))
        store.commit('SetLatestPriceBNB', {
          price: parseFloat(result.data.data.result.ethusd)
        })
      })
      .catch(err => {
        // this.loading = false
        console.log('Error getting LatestBNBPrice.', err)
        // show friendly error in user screen
      })

      Promise.resolve(OpenSeaAPI.getLatestETHPrice())
      .then(result => {
        // console.log('result getLatestETHPrice')
        // console.log(result)
        // console.log(parseFloat(result.data.result.ethusd))
        store.commit('SetLatestPriceETH', {
          price: parseFloat(result.data.data.result.ethusd)
        })
      })
      .catch(err => {
        // this.loading = false
        console.log('Error getting LatestETHPrice.', err)
        // show friendly error in user screen
      })

      Promise.resolve(OpenSeaAPI.getLatestMATICPrice())
      .then(result => {
        // console.log('result getLatestMATICPrice')
        // console.log(result)
        // console.log(parseFloat(result.data.data.result.maticusd))
        store.commit('SetLatestPriceMATIC', {
          price: parseFloat(result.data.data.result.maticusd)
        })
      })
      .catch(err => {
        // this.loading = false
        console.log('Error getting LatestETHPrice.', err)
        // show friendly error in user screen
      })

      // https://explorer.kcc.io/api/chainstat/kcs
      Promise.resolve(OpenSeaAPI.getLatestKCSPrice())
      .then(result => {
        // console.log('result getLatestKCSPrice')
        // console.log(result)
        // console.log(parseFloat(result.data.data.result.maticusd))
        store.commit('SetLatestPriceKCS', {
          price: parseFloat(result.data.data.data.priceUsd)
        })
      })
      .catch(err => {
        // this.loading = false
        console.log('Error getting LatestKCSPrice.', err)
        // show friendly error in user screen
      })
    },
    openSearch () {
      this.$router.push('/tokens/search')
      // this.searchDialog = true
    },
    searchClicked (e) {
      // console.log('search')
      if (e === 'click' && this.search !== '') {
        this.searchAll()
        return
      }
      if (e.keyCode === 13 && this.search !== '') {
        // alert('Enter was pressed')
        this.searchAll()
        return
      }
      this.searchTrigger = false
    },
    searchAll () {
      this.loadingData = true // .where('expire', '>', today)
      this.searchTrigger = true
      this.searchResults = []
      let search = this.search.toLowerCase()
      // console.log(this.search)
      let query = db.collection('tokens').where('keywords', 'array-contains-any', [search, '#' + search])
      query.get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log('No matching documents.')
          this.loadingData = false
          return
        }
        snapshot.forEach(doc => {
          // console.log(doc.id, '=>', doc.data())
          var obj = doc.data()
          obj.id = doc.id
          obj.readmore = false
          obj.price = 0
          obj.price_text = '0'
          obj.mc = 0
          this.searchResults.push(obj)
          this.loadingData = false
        })
      })
      .catch(err => {
        console.log('Error getting documents', err)
      })
    },
    likeThisItem (item) {
      if (this.getLikes.indexOf(item.id) > -1) {
        this.snackbarText = 'You have already liked this item'
        this.snackbarWarning = true
        return
      }
      store.commit('SetLikes', item.id)
      let dispatchObj = {
        likes: item.likes += 1
      }
      db.collection('tokens').doc(item.id).update(dispatchObj)
      .then(() => {
          // console.log('Item likes in news bucket updated')
          this.snackbarText = 'Your like has been recorded'
          this.snackbar = true
        })
      .catch(error => {
          console.log(error)
        })
    },
    lookupLiked (item) {
      // console.log(item)
      return this.getLikes.indexOf(item.id) > -1
    },
    routerGo (route) {
      if  (window.width < 770)  {
        this.drawer = false
      }
      this.$router.push(route)
    },
    onDeviceReady() {
      // console.log(device.cordova)
      // console.log(device.model)
      // console.log(device.platform)
      store.commit('SetDevice', {
        // platform: device.platform
      })
    },
    showSearchField () {
      this.showSearch = true
      setTimeout(() => {
        this.$refs.search.focus()
      })
    },
    clearSearch () {
      this.search = ''
      this.searchTrigger = false
    },
    changeLanguage () {
      // console.log(this.getLanguage)
      store.commit('SetLanguage', {
        language: this.getLanguage === 'en' ? 'id' : 'en'
      })
      /* this.userData.language = lang
      var obj = {
        language: lang
      }
      this.saveSettingsData(obj) */
    },
    openPrivacy () {
      // this.readFile()
      if (this.getLanguage === 'en') {
        this.fileName = 'https://tokenmonster.app/privacy/Token%20Monster%20Privacy%20Policy%20ENG.pdf'
      } else if (this.getLanguage === 'id') {
        this.fileName = ''
      }
      this.dialogFilePreview = true
      /* if (device.platform === 'Android') {
        window.open(this.fileName, '_blank')
      } else {
        this.dialogFilePreview = true
      } */
    },
    openTerms () {
      // this.readFile()
      if (this.getLanguage === 'en') {
        this.fileName = 'https://tokenmonster.app/terms/Token%20Monster%20Terms%20and%20Conditions%20ENG.pdf'
      } else if (this.getLanguage === 'id') {
        this.fileName = ''
      }
      this.dialogFilePreview = true
      /* if (device.platform === 'Android') {
        window.open(this.fileName, '_blank')
      } else {
        this.dialogFilePreview = true
      } */
    },
    gotoLink (link) {
      window.open(link, '_blank')
    },
    signOut () {
      firebase.auth().signOut().then(() => {
        this.$router.push('/')
        window.location.reload()
      })
    },
    handleResize () {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
      // console.log(this.window.width)
      // this.window.width > 770 ? this.drawer = false : this.drawer = true
    },
    menuChangeClicked () {
      if  (window.width < 770)  {
        this.drawer = false
      }
      // navigate to home #div
    },
    handleScroll () {
      // console.log(window.scrollY)
      this.scrollY = window.scrollY
      if (window.scrollY > 450) {
        this.scrolled = true
      } else {
        this.scrolled = false
      }
      if (window.scrollY > 250) {
        this.scrolledHome = true
      } else {
        this.scrolledHome = false
      }
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    timer(ms) {
      return new Promise(res => setTimeout(res, ms));
    },
    timeDifference(previous) {

      var current = Math.round(new Date() / 1000)
      // console.log(current)
      var msPerMinute = 60
      var msPerHour = msPerMinute * 60
      var msPerDay = msPerHour * 24
      var msPerMonth = msPerDay * 30
      var msPerYear = msPerDay * 365
      var elapsed = current - previous

      if (elapsed < msPerMinute) {
        return Math.round(elapsed/1000) + ' seconds ago';
      }
      else if (elapsed < msPerHour) {
        return Math.round(elapsed/msPerMinute) + ' minutes ago'
      }
      else if (elapsed < msPerDay ) {
        return Math.round(elapsed/msPerHour ) + ' hours ago'
      }
      else if (elapsed < msPerMonth) {
        return Math.round(elapsed/msPerDay) + ' days ago'
      }
      else if (elapsed < msPerYear) {
        return Math.round(elapsed/msPerMonth) + ' months ago'
      }
      else {
        return Math.round(elapsed/msPerYear ) + ' years ago'
      }
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Syncopate&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jura:wght@300;400;500&display=swap');
#mola-nft-event-app {
  font-family: 'Jura', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #221c2b;
  background: #221c2b;
}
.appmenu { // text-shadow: 0 0 2px rgba(167, 116, 149, 1),  0 0 20px rgba(10, 175, 230, 0);
    font-family: 'Comfortaa'; // Font Brown
    letter-spacing: 0.25em;
    font-size: 60px;
}
.appname { // text-shadow: 0 0 2px rgba(167, 116, 149, 1),  0 0 20px rgba(10, 175, 230, 0);
    font-family: 'Syncopate', sans-serif; // Font Brown
    letter-spacing: 0.25em;
    font-size: 80px;
}
</style>
