<template>
  <div class="home" >
    <v-slide-y-transition mode="out-in">
      <v-layout column >
        <!-- ####################### SKELETON LOADERS ####################### -->
        <v-layout column v-if="loading" :style="windowWidth < 770 ? 'margin-top:80px' : 'margin-top:100px;margin-left:5%;margin-right:5%'">

          <v-layout column align-center>
           <v-spacer></v-spacer>
               <v-skeleton-loader
                 class="mxn-auto"
                 :width="windowWidth < 770 ? '95%' : '80%'"
                 height="400"
                 type="image"
               ></v-skeleton-loader>
           <v-spacer></v-spacer>
         </v-layout>

        </v-layout>

        <v-layout column align-center v-else-if="slugLoaded" style="margin-top:150px">
          <v-progress-circular
             indeterminate
             color="#1B5E20"
           ></v-progress-circular>
          <p class="mt-2"></p>
        </v-layout>

        <!-- ############################################## COMPONENTS  ##############################################  -->

        <v-layout column v-else style="margin-top:-64px">

          <v-card flat
              style="border-radius:0px"
              :dark="dark"
              :width="windowWidth < 770 ? '' : '100%'"
              :min-height="windowHeight"
            >

            <v-layout row wrap :style="windowWidth < 1400 ? 'margin-top:150px' : 'margin-right:10%;margin-left:10%;margin-top:200px'">

              <v-col
                 cols="12"
                 sm="12"
               >
                <p v-if="view === 0"  style="font-size:28px" class="font-weight-medium text-center">Welcome to the MOLA Chill Club NFT Authentication Page.<br>Please connect your wallet to access the site.</p>
                <p v-if="view === 1 || view === 2"  style="font-size:28px" class="font-weight-medium text-center">Mola Chill 2303</p>

             </v-col>

              <v-col
                 cols="12"
                 sm="12"
               >
               <v-layout v-if="view === 0 && this.getChain === '0x1' && !isMobileDevice" column align-center pl-4 pr-4 :style="windowWidth < 770 ? '' : 'margin-left:25%;margin-right:25%'">
                 <p class="mt-2"></p>
                 <MetaMaskConnect :isMobileDevice="isMobileDevice" style="width:100%;" ref="mmConnect" buttonType="large" :windowWidth="windowWidth" :windowHeight="windowHeight" :dark="dark">
                 </MetaMaskConnect>
                 <p class="mt-0"></p>
                  <WalletConnect style="width:100%;" ref="walletConnect" buttonType="large" :windowWidth="windowWidth" :windowHeight="windowHeight" :dark="dark">
                  </WalletConnect>
                  <p class="mt-0"></p>
                  <p style="font-size:14px" class="font-weight-medium text-center">Works best with Chrome or Brave</p>
               </v-layout>

               <v-layout v-if="view === 0 && this.getChain != '0x1' && !isMobileDevice" column align-center pl-4 pr-4 :style="windowWidth < 770 ? '' : 'margin-left:25%;margin-right:25%'">
                 <v-btn v-if="this.getChain != '0x1'" large style="width:100%;text-transform: none !important" :color="dark ? '#132f5d' : 'blue lighten-5'"  @click="addETHNetwork">
                   <v-icon left >mdi-swap-horizontal</v-icon>Switch to Ethereum Mainnet
                 </v-btn>
               </v-layout>

               <v-layout v-if="view === 0 && isMobileDevice" column align-center pl-4 pr-4 :style="windowWidth < 770 ? '' : 'margin-left:25%;margin-right:25%'">
                 <v-btn v-if="$route.name === 'Home'" large style="width:100%;text-transform: none !important" :color="dark ? '#132f5d' : 'blue lighten-5'"  @click="gotoMMLink('https://metamask.app.link/dapp/chill2303.molanft.io/mmobile')">
                   <v-icon left >mdi-wallet</v-icon>Connect Wallet
                 </v-btn>
                 <MetaMaskConnect v-if="$route.name === 'MMobile'" :isMobileDevice="isMobileDevice" style="width:100%;" ref="mmConnect" buttonType="large" :windowWidth="windowWidth" :windowHeight="windowHeight" :dark="dark">
                 </MetaMaskConnect>
               </v-layout>


               <v-layout v-if="view === 1" column align-center pl-4 pr-4 :style="windowWidth < 770 ? '' : 'margin-left:25%;margin-right:25%'">

                 <p v-if="windowWidth > 770" style="font-size:18px" class="font-weight-medium text-center">Your NFT has been verified. Welcome, Mola Chill Club member!</p>
                 <p v-else style="font-size:18px" class="font-weight-medium text-center">Your NFT has been verified.<br>Welcome, Mola Chill Club member!</p>
                 <p class="mt-0"></p>
                 <v-btn @click="gotoEventPage" style="width:100%;" large :color="dark ? '#132f5d' : 'blue lighten-5'">
                   Continue to the Event page
                 </v-btn>

               </v-layout>

               <v-layout v-if="view === 2" column align-center pl-4 pr-4 :style="windowWidth < 770 ? '' : 'margin-left:25%;margin-right:25%'">

                 <p style="font-size:18px" class="font-weight-medium text-center">Unfortunately you are not owning a Genesis Pass NFT yet.</p>
                 <p class="mt-0"></p>
                 <v-btn @click="gotoLink('https://opensea.io/collection/mola-chill-club-1')" style="width:100%;text-transform: none !important;" large :color="dark ? '#132f5d' : 'blue lighten-5'">
                   Buy a Genesis Pass NFT on OpenSea
                 </v-btn>

               </v-layout>

              </v-col>

            </v-layout>

          </v-card>

        </v-layout>

      </v-layout>
    </v-slide-y-transition>


    <!-- ############################################## BOTTOM NAVIGATION ##############################################  -->


      <!-- ########################## NFT DETAIL DIALOG ########################## -->

      <v-dialog v-model="checkNFTDialog"  transition="dialog-bottom-transition" max-height="400" max-width="500">
         <v-card :dark="dark">
           <p class="mt-0"></p>

           <v-layout column pa-4 style="margin-left:20px"> <!-- We intend to do AMA on twitch very soon. -->

             <p>Verifying your NFTs...</p>
             <p class="mt-0"></p>
             <v-progress-linear
                indeterminate
                color="#132f5d"
              ></v-progress-linear>

           </v-layout>
         </v-card>
       </v-dialog>

      <v-snackbar :timeout="4000" :bottom="'bottom'" v-model="snackbar">
        <v-icon style="margin-left:10px" color="green">mdi-check-circle-outline</v-icon>
        <span >{{ snackbarText }}</span>
              <v-btn text color="#1B5E20" @click.native="snackbar = false">Close</v-btn>
      </v-snackbar>

      <v-snackbar :timeout="4000" :bottom="'bottom'" v-model="snackbarWarning">
        <v-icon color="amber">mdi-alert-outline</v-icon>
        <span style="padding-left:10px">{{ snackbarText }}</span>
        <v-spacer></v-spacer>
      </v-snackbar>


  </div>
</template>

<script>
// @ is an alias to /src
import store from '@/store/index'
// import { db } from '@/main'
import MolaNftAPI from '@/clients/MolaNftAPI'
// import VueCountdown from '@chenfengyuan/vue-countdown'
import MetaMaskConnect from '@/views/app/MetaMaskConnect'
import WalletConnect from '@/views/app/WalletConnect'
const now = new Date()
const launchDateTime = new Date('2021-07-15 00:00:00')
export default {
  name: 'Home',
  props: {
    dark: Boolean,
    windowWidth: Number,
    windowHeight: Number,
    drawer: Boolean,
    welcome: String,
    isMobileDevice: Boolean
  },
  data () {
    return {
      // lang: this.$store.state.lang,
      fab: false,
      showBottomNav: false,
      loading: true,
      loadingData: false,
      loadingPromoted: true,
      snackbar: false,
      snackbarWarning: false,
      snackbarText: '',
      search: '',
      firstSearch: false,
      launch: launchDateTime - now,
      nowList: new Date().getTime(),
      show: true,
      slugLoaded: false,
      resultInterval: null,
      welcomeDialog: false,
      showWelcomeCheck: false,
      tokenDetailDialog: false,
      checkNFTDialog: false,
      realnessDialog: false,
      contractAddress: '0x49c248641eb904c9d5a33904d388212a89218187',
      srcPancake: '',
      srcPoocoin: '',
      srcBsc: '',
      srcBscDark: '',
      logoSrc: '',
      logoSrcDark: '',
      logoSrcHeader: '',
      srcTikTok: '',
      tokenItem: {},
      limitPromoted: 10,
      tempImage: '',
      srcPolygon: '',
      srcBinance: '',
      srcKuCoin: '',
      srcTron: '',
      srcPolkadot: '',
      localPromotedTokens: [],
      localLaunchQueueTokens: [],
      view: 0,
      mmOpened: false,
      tokenAmount: 0,
      genesisPassTokenID: [
        '56930163533861193935171699756209854822368293045531932816878204024345465978980',
        '56930163533861193935171699756209854822368293045531932816878204017748396212324',
        '56930163533861193935171699756209854822368293045531932816878204018847907840100',
        '56930163533861193935171699756209854822368293045531932816878204025444977606706',
        '56930163533861193935171699756209854822368293045531932816878204026544489234482',
        '56930163533861193935171699756209854822368293045531932816878204027644000862258',
        '1',
        '2',
        '3'
      ],
      genesisPassTokenName: [
        'Genesis Pass',
        'Genesis Pass',
        'Genesis Pass',
        'Genesis Pass',
        'Mola Chill Ticket',
        'Mola Chill Ticket',
        'Mola Chill 2303 NFT Tickets',
        'Mola Chill 2303 NFT Tickets',
        'Mola Chill 2303 NFT Tickets'
      ]
    }
  },
  watch: {
    windowWidth () {
      if (this.windowWidth < 770) {
        this.showBottomNav = false   // for now
      } else {
        this.showBottomNav = false
      }
    },
    checkAccount (){
      if (!this.mmConnected && !this.walletConnected) {
        this.view = 0
      } else {
        setTimeout(() => {
          this.checkNFTOwned()
        }, 1000)
      }
    },
    getChain () {
      // this.getTokenBalance()
    },
  },
  computed: {
    // getLanguage () {
      // return this.$store.state.user.language
    // },
    getUser () {
      return this.$store.state.user
    },
    isLoggedIn () {
      return this.$store.state.user.isLoggedIn
    },
    checkAccount () {
      return this.$store.state.user.accounts
    },
    mmConnected () {
      return this.$store.state.user.mmConnected
    },
    binanceConnected () {
      return this.$store.state.user.binanceConnected
    },
    walletConnected () {
      return this.$store.state.user.walletConnected
    },
    getChain () {
      return this.$store.state.user.networkChainID
    },
    getOwnsNFTs () {
      // console.log(this.$store)
      // return this.$store.getters.state.device.platform
      return this.$store.state.user.ownsNFTs
    },
  },
  beforeDestroy () {
    clearInterval(this.resultInterval)
  },
  destroyed () {
    // window.removeEventListener('resize', this.handleResize)
  },
  mounted () {
  },
  components: {
    MetaMaskConnect,
    WalletConnect
  },
  created () {
    store.commit('ShowNav')
    this.init ()
  },
  methods: {
    init () {
      // console.log('### window width #######')
      // console.log(this.windowWidth)
      this.loading = true
      this.loadingPromoted = true
      this.showButtons()
      this.logoSrc = './img/logo/tokenmonster_logo.png'
      this.logoSrcHeader = './img/logo/tokenmonster_logo_01.png'
      this.logoSrcDark = './img/logo/tokenmonster_logo.png'
      this.srcdachshundWhite = './img/icons/dachshund_white.png'
      this.srcTikTok = './img/icons/tiktok_light_orange.png'
      this.srcTikTokWhite = './img/icons/tiktok_white.png'
      this.srcPancake = './img/icons/pancakeswap.png'
      this.srcPoocoin = './img/icons/poocoin.png'
      this.srcBsc = './img/icons/bscscan_light.png'
      this.srcBscDark = './img/icons/bscscan_dark.png'
      this.srcPolygon = './img/icons/polygon.png'
      this.srcBinance = './img/icons/binance-coin-logo.png'
      this.srcKuCoin = './img/icons/kucoin.png'
      this.srcTron = './img/icons/tron.png'
      this.srcPolkadot = './img/icons/polkadot.png'

      // console.log('this.$route.params.slug')
      // console.log(this.$route.params.slug)
      if (this.$route.params.slug === undefined || this.$route.params.slug === null) {
        // console.log('no unique slug loading needed')
        // this.loadPromotedTokens()
        // this.checkWelcomeWindow()

        // Set the interval for loading the price quotes every minute
        this.resultInterval = setInterval(() => {
          // this.pollPromotedTokens()
        }, 60000)
      } else {
        // console.log('show video - from slug')
        // console.log(this.$route.params.slug)
        //this.loading = false
        this.slugLoaded = true
        // this.lookupTokenBySlug()
      }

      window.ethereum.request({ method: 'net_version' })
        .then((nwId) => {
          // console.log(nwId)
          var nw = parseInt(nwId).toString(16)
          store.commit('SetNetworkChainID', {
            networkChainID: '0x' + nw
          })
        })
        .catch((error) => console.log(error))

      window.ethereum.on('chainChanged', (chainId) => {
        // console.log('chainId changed', chainId)
        // Handle the new chain.
        // Correctly handling chain changes can be complicated.
        // We recommend reloading the page unless you have good reason not to.
        store.commit('SetNetworkChainID', {
          networkChainID: chainId
        })
      })

      if (this.getOwnsNFTs) {
        this.view = 1
      }
    },
    connectWallet() {
      this.$emit('connectWalletClicked')
    },
    checkNFTOwned () {
      // console.log('Check the NFTs Owned by  Wallet: ' + this.getUser.accounts[0])
      // console.log('Check if logged in: ' + this.isLoggedIn)
      this.checkNFTDialog = true
      this.tokenAmount = 0
      Promise.resolve(MolaNftAPI.getNftForWallet(this.getUser.accounts[0]))
      .then(result => {
        // console.log(result)
        // this.loading = false
        var tokenArr = result.data.result
        for (var i in tokenArr) {
          var token_id = tokenArr[i].token_id
          var token_address = tokenArr[i].token_address
          if (this.genesisPassTokenID.includes(token_id) || (token_address === '0x598C4cCedFf113810F2a994f17e27760f62dbDeC' && this.genesisPassTokenID.includes(token_id))) {
            // console.log('Wallet OWNS one of the Mola Tokens!')
            this.tokenAmount += parseInt(tokenArr[i].amount)
            this.view = 1
            var index = this.genesisPassTokenID.indexOf(token_id)
            store.commit('SetNFTChanges', {
              ownsNFTs: true,
              nftName: this.genesisPassTokenName[index]
            })
          }
        }
        if (result.data.result.length === 0) {
          // console.log('Wallet does NOT own a Mola Token!')
          this.view = 2
        }
        setTimeout(() => {
          this.checkNFTDialog = false
        }, 1000)
      })
      .catch(err => {
        this.loading = false
        console.log('Error getting documents.', err)
        // show friendly error in user screen
      })
    },
    async addETHNetwork () {
      // console.log(this.getUser.walletProvider)
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x1' }],
        });
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [{
                chainId: '0x1', // A 0x-prefixed hexadecimal string
                chainName: 'Ethereum Mainnet',
                nativeCurrency: {
                  name: 'Ethereum',
                  symbol: 'ETH', // 2-6 characters long
                  decimals: 18,
                },
                rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
                blockExplorerUrls:['https://etherscan.io/']
              }],
            })
          } catch (addError) {
            // handle "add" error
          }
        }
        // handle other "switch" errors
      }
    },
    gotoEventPage () {
      this.$router.push('/event')
    },
    showButtons () {
      this.show = false
      setTimeout(() => {
        this.show = true
        this.loading = false
      }, 1000)
    },
    timer(ms) {
      return new Promise(res => setTimeout(res, ms));
    },
    setWelcomeUser () {
      var obj = {
        welcome: true,
      }
      this.saveSettingsData(obj)
    },
    openTerms () {
      this.$emit('termsClicked')
    },
    openPrivacy () {
      this.$emit('privacyClicked')
    },
    openRealness () {
      this.realnessDialog = true
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    gotoLink (link) {
      window.open(link, '_blank')
    },
    gotoMMLink (link) {
      this.mmOpened = true
      window.open(link, '_blank')
    },
    handleSuccess (e) {
      console.log(e);
      this.snackbarText = 'Contract Address copied.'
      this.snackbar = true
    },
    handleError(e) {
      console.log(e);
    },
    openLink(link) {
      window.open(link, '_blank')
    },
    timeDifference(previous) {

      var current = Math.round(new Date() / 1000)
      // console.log(current)
      var msPerMinute = 60
      var msPerHour = msPerMinute * 60
      var msPerDay = msPerHour * 24
      var msPerMonth = msPerDay * 30
      var msPerYear = msPerDay * 365
      var elapsed = current - previous

      if (elapsed < msPerMinute) {
        return Math.round(elapsed/1000) + ' Sec ago';
      }
      else if (elapsed < msPerHour) {
        return Math.round(elapsed/msPerMinute) + ' Min ago'
      }
      else if (elapsed < msPerDay ) {
        return Math.round(elapsed/msPerHour ) + ' Hrs ago'
      }
      else if (elapsed < msPerMonth) {
        return Math.round(elapsed/msPerDay) + ' Days ago'
      }
      else if (elapsed < msPerYear) {
        return Math.round(elapsed/msPerMonth) + ' Months ago'
      }
      else {
        return Math.round(elapsed/msPerYear ) + ' Years ago'
      }
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Syncopate&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;500&display=swap');
.homeTitle {
  font-family: 'Comfortaa';
  color:#2b7703;
  font-size: 32px;
  font-weight: 900;
  text-align: center;
  // text-transform: uppercase;
}
.homeTitleMobile {
  font-family: 'Comfortaa';
  color:#2b7703;
  font-size: 18px;
  font-weight: 900;
  text-align: center;
  // text-transform: uppercase;
}
.cardTitle {
  font-family: 'Comfortaa';
  color:#FFF;
  font-size: 22px;
  font-weight: 900;
  text-align: center;
  // text-transform: uppercase;
}
.cardTitleMobile {
  font-family: 'Comfortaa';
  color:#FFF;
  font-size: 18px;
  font-weight: 900;
  text-align: center;
  // text-transform: uppercase;
}
.homeTextLarge {
  font-family: 'Comfortaa';
  color:#FFF;
  font-size: 28px;
  font-weight: 900;
  text-align: center;
  // text-transform: uppercase;
}
.homeText {
  font-family: 'Comfortaa';
  color:#FFF;
  font-size: 18px;
  font-weight: 900;
  text-align: center;
  // text-transform: uppercase;
}
.clockText {
  // font-family: 'Digital-7', monospace;
  font-size: 22px;
  // transform: translate(-50%, -50%);
  color: #1B5E20;
  text-shadow: 0 0 2px rgba(163, 163, 163, 1),  0 0 2px rgba(163, 163, 163, 1);
}
.clockDarkText{
  // font-family: 'Digital-7', monospace;
  font-size: 22px;
  // transform: translate(-50%, -50%);
  color: #F9FBE7;
  text-shadow: 0 0 2px rgba(163, 163, 163, 1),  0 0 2px rgba(163, 163, 163, 1);
}
.clock {
  font-family: 'Digital-7', monospace;
  font-size: 40px;
  // transform: translate(-50%, -50%);
  color: #1B5E20;
  text-shadow: 0 0 5px rgba(163, 163, 163, 1),  0 0 5px rgba(163, 163, 163, 1);
}
.clockDark{
  font-family: 'Digital-7', monospace;
  font-size: 40px;
  // transform: translate(-50%, -50%);
  color: #F9FBE7;
  text-shadow: 0 0 5px rgba(163, 163, 163, 1),  0 0 5px rgba(163, 163, 163, 1);
}
.myBgLight {
  background: linear-gradient(to bottom, rgba(0,0,0,.0), rgba(0,0,0,.5)), url('/img/home/home_light_01.jpeg') no-repeat;
  background-size: cover;
}
.myBgDark {
  background:url('/img/home/home_dark_02.jpeg') no-repeat;
  background-size: cover;
}
.v-skeleton-loader__image {
    height: 500px;
    border-radius: 0;
}
.wrap-text {
  word-break: normal;
}

/* This is for documentation purposes and will not be needed in your application */
#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}

</style>
